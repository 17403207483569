<template>
  <div class="card card-form">
    <div class="card-header">
      <h4>Formulário de Cadastro de Documentos</h4>
    </div>
    <div class="card-body">
      <div class="form-group">
        <label for="rendaMensal">Renda Mensal</label>
        <money3 v-model="rendaMensal" class="form-control mb-1" type="text" v-bind="config" required />
      </div>

      <div class="form-group">
        <label for="sobreNegocio">Sobre o Negócio</label>
        <textarea v-model="sobreNegocio" class="form-control" id="sobreNegocio" rows="3" maxlength="500"
          required></textarea>
      </div>

      <div class="form-group">
        <label for="linkRedesSociais">Link de Mídia Social</label>
        <input v-model="linkRedesSociais" class="form-control" type="text" id="linkRedesSociais" maxlength="255"
          required />
      </div>

      <h5>Associados</h5>
      <hr>
      <div v-for="(associado, index) in associados" :key="index">
        <div class="form-group">
          <label for="documentoCpf">Documento CPF do Associado</label>
          <input v-model="associado.documentoCpf" class="form-control" type="text" required />
        </div>
        <div class="form-group">
          <label for="nomeAssociado">Nome Completo do Associado</label>
          <input v-model="associado.nome" class="form-control" type="text" required />
        </div>
        <div class="form-group">
          <label for="nomeMae">Nome da Mãe do Associado</label>
          <input v-model="associado.motherName" class="form-control" type="text" required />
        </div>
        <div class="form-group">
          <label for="dataNascimento">Data de Nascimento do Associado</label>
          <input v-model="associado.birthDate" class="form-control" type="date" required />
        </div>
        <div class="form-group">
          <label for="tipoAssociado">Tipo de Associado</label>
          <select v-model="associado.type" class="form-control" required>
            <option value="partner">Sócio</option>
            <option value="attorney">Procurador</option>
            <option value="personinvolved">Pessoa Envolvida</option>
          </select>
        </div>
        <button @click="removerAssociado(index)" type="button" class="btn btn-danger mb-3">Remover Associado</button>
        <hr>
      </div>
      <button @click="adicionarAssociado" type="button" class="btn btn-primary mb-3">Adicionar Associado</button>


      <h5>Empresa</h5>
      <hr>
      <div class="form-group"
        v-if="$route.params.tipoEmpresa === 'ltda' || $route.params.tipoEmpresa === 'eireli' || $route.params.tipoEmpresa === 'slu'">
        <label for="lastContract">Contrato Social ou Última Alteração Consolidada</label>
        <input @change="handleFileUpload('lastContract', $event)" class="form-control" type="file" id="lastContract"
          required />
      </div>

      <div class="form-group" v-if="tipoEmpresa === 'individualEntrepreneur' || tipoEmpresa === 'mei'">
        <label for="documentoCnpj">Documento CNPJ</label>
        <input @change="handleFileUpload('documentoCnpj', $event)" class="form-control" type="file" id="documentoCnpj"
          required />

      </div>

      <div class="form-group" v-if="tipoEmpresa === 'association' || tipoEmpresa === 'sa'">
        <label for="ataEleicao">Ata de Eleição da Diretoria</label>
        <input @change="handleFileUpload('ataEleicao', $event)" class="form-control" type="file" id="ataEleicao"
          required />
      </div>

      <div class="form-group" v-if="tipoEmpresa === 'association' || tipoEmpresa === 'sa'">
        <label for="estatuto">Estatuto</label>
        <input @change="handleFileUpload('estatuto', $event)" class="form-control" type="file" id="estatuto" required />
      </div>

      <h5>Documentos Pessoais</h5>
      <div class="form-group">
        <label for="tipoDocumento">Tipo de Documento</label>
        <select v-model="tipoDocumento" class="form-control" required>
          <option value="">Selecione</option>
          <option value="RG">RG</option>
          <option value="CNH">CNH</option>
        </select>
      </div>

      <div v-if="tipoDocumento === 'CNH'">
        <hr>
        <div v-for="(cnh, index) in cnhs" :key="index">
          <div class="form-group">
            <label for="cnhSelfie">Selfie segurando a CNH</label>
            <input @change="handleFileUpload('cnhSelfie', $event)" class="form-control" type="file" />
          </div>
          <div class="form-group">
            <label for="cnhFoto">Foto da CNH (frente + verso)</label>
            <input @change="handleFileUpload('cnhFoto', $event)" class="form-control" type="file" />
          </div>

          <button @click="removerCNH(index)" type="button" class="btn btn-danger mb-3">Remover CNH</button>
          <hr>
        </div>
        <button @click="adicionarCNH" type="button" class="btn btn-primary mb-3">Adicionar CNH</button>
      </div>
      <div v-if="tipoDocumento === 'RG'">
        <hr>
        <div v-for="(rg, index) in rgs" :key="index">
          <div class="form-group">
            <label for="rgSelfie">Selfie segurando o RG</label>
            <input @change="handleFileUpload('rgSelfie', $event)" class="form-control" type="file" />
          </div>
          <div class="form-group">
            <label for="rgFront">Frente do RG</label>
            <input @change="handleFileUpload('rgFront', $event)" class="form-control" type="file" />
          </div>
          <div class="form-group">
            <label for="rgBack">Verso do RG</label>
            <input @change="handleFileUpload('rgBack', $event)" class="form-control" type="file" />
          </div>

          <button @click="removerRG(index)" type="button" class="btn btn-danger mb-3">Remover RG</button>
          <hr>
        </div>
        <button @click="adicionarRG" type="button" class="btn btn-primary mb-3">Adicionar RG</button>
      </div>
    </div>

  </div>
  <button @click="enviarDocumentos($route.params.galaxId, $route.params.galaxHash, $route.params.tipoEmpresa)"
    type="button" class="btn btn-success mb-3 mt-4">Enviar Documentos</button>
</template>

<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import { Money3Component } from "v-money3";

export default {
  components: { money3: Money3Component },

  data() {
    return {
      config: {
        masked: false,
        prefix: "R$ ",
        suffix: "",
        thousands: ".",
        decimal: ",",
        precision: 2,
        disableNegative: false,
        disabled: false,
        min: null,
        max: null,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
        shouldRound: true,
        focusOnRight: false,
      },
      rgs: [],
      cnhs: [],
      associados: [],
      rendaMensal: '',
      sobreNegocio: '',
      linkRedesSociais: '',
      documentoCpf: '',
      nomeAssociado: '',
      tipoDocumento: '',
      nomeMae: '',
      dataNascimento: '',
      tipoAssociado: '',
      contratoSocial: null,
      documentoCnpj: null,
      ataEleicao: null,
      estatuto: null,
      galaxyId: '',
      galaxyHash: ''

    }

  },
  methods: {
    adicionarRG() {
      this.rgs.push({
        selfie: null,
        front: null,
        back: null
      });
    },
    removerRG(index) {
      this.rgs.splice(index, 1);
    },
    handleSelfieRGChange(index, event) {
      const file = event.target.files[0];
      this.rgs[index].selfie = file;
    },
    handleFrontRGChange(index, event) {
      const file = event.target.files[0];
      this.rgs[index].front = file;
    },
    handleBackRGChange(index, event) {
      const file = event.target.files[0];
      this.rgs[index].back = file;
    },
    adicionarCNH() {
      this.cnhs.push({
        selfie: null,
        picture: null
      });
    },
    removerCNH(index) {
      this.cnhs.splice(index, 1);
    },
    handleSelfieChange(index, event) {
      const file = event.target.files[0];
      this.cnhs[index].selfie = file;
    },
    handleFotoChange(index, event) {
      const file = event.target.files[0];
      this.cnhs[index].picture = file;
    },
    adicionarAssociado() {
      this.associados.push({
        documentoCpf: '',
        nome: '',
        motherName: '',
        birthDate: '',
        type: ''
      });
    },
    removerAssociado(index) {
      this.associados.splice(index, 1);
    },
    async handleFileUpload(fieldName, event) {
      const file = event.target.files[0];

      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        this[fieldName] = base64String;
      };

      reader.readAsDataURL(file);
    },

    async enviarDocumentos(galaxyId, galaxyHash, tipoEmpresa) {
      const toaster = createToaster({});
      const token = this.$store.state.token;

      const associadosFormatados = this.associados.map(associado => ({
        document: associado.documentoCpf,
        name: associado.nome,
        motherName: associado.motherName,
        birthDate: associado.birthDate,
        type: associado.type
      }));

      try {
        const formData = {
          Fields: {
            monthlyIncome: this.rendaMensal,
            about: this.sobreNegocio,
            socialMediaLink: this.linkRedesSociais
          },
          Associate: associadosFormatados,
          Documents: {
            Company: {
              lastContract: this.lastContract,
              cnpjCard: this.documentoCnpj,
              electionRecord: this.ataEleicao,
              statute: this.estatuto
            },
            Personal: {
              CNH: {
                selfie: this.cnhs.map(cnh => cnh.selfie),
                picture: this.cnhs.map(cnh => cnh.picture)
              },
              RG: {
                selfie: this.rgs.map(rg => rg.selfie),
                front: this.rgs.map(rg => rg.front),
                back: this.rgs.map(rg => rg.back)
              }
            }
          },
          galaxyId: galaxyId,
          galaxyHash: galaxyHash,
          tipoEmpresa: tipoEmpresa
        };

        console.log('=======FORM DATA======', formData)

        const response = await axios.post(`${this.$baseURL}/documento/envioDocumento`, formData, {
          headers: {
            "x-access-token": token,
          },
        });

        console.log(response.data);

        if (response.data.type === true) {
          toaster.success('Documentos enviados com sucesso');
        }
      } catch (error) {
        console.error('Erro ao enviar documentos:', error);
        // Exibir toast de erro com base na mensagem de erro recebida
        if (error.response && error.response.data && error.response.data.error) {
          const apiError = error.response.data.error;
          if (apiError.details) {
            const validationErrors = Object.values(apiError.details).flat();
            toaster.error('Erro ao enviar documentos: ' + validationErrors.join(', '));
          } else {
            toaster.error('Erro ao enviar documentos: ' + apiError.message);
          }
        } else {
          toaster.error('Erro ao enviar documentos: ' + error.message);
        }
      }
    }

  }
}
</script>

<style scoped>
.card-form {
  margin-top: 20px;
}
</style>
