<template>
  <BreadcrumbsColaborador :breadcrumbs="breadcrumbs" :router="$router" />

  <div class="py-4 container-fluid">
    <div class="card">
      <div class="container mt-2">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12 d-flex align-items-center">
            <button class="btn btn-primary btn-new" @click="cadColaborador">
              Novo Colaborador
            </button>
          </div>
          <div class="col-lg-8 col-md-6 col-sm-12 d-flex align-items-center">
            <div class="d-flex w-90">
              <select class="form-control input-field">
                <option disabled selected hidden>Selecione uma opção</option>
                <option value="Nome">Nome</option>
                <option value="CPF">CPF</option>
                <option value="E-mail">E-mail</option>
              </select>

              <input
                type="text"
                class="form-control input-field"
                placeholder="Pesquisar"
              />
              <button class="btn btn-primary search-btn btn-new">Ir</button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <v-breadcrumbs
            bg-color="primary"
            :items="['Foo', 'Bar', 'Fizz']"
          ></v-breadcrumbs>
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Nome
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  CPF
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Status
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Dt. Nascimento
                </th>
                <th class="text-secondary opacity-7"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="colaborador in colaboradores" :key="colaborador.id">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ colaborador.user.nome }}</h6>
                      <p class="text-xs text-secondary mb-0">
                        {{ colaborador.user.email }}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs text-secondary mb-0">
                    {{ colaborador.cpf }}
                  </p>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="badge badge-sm bg-gradient-success">
                    {{ colaborador.status }}
                  </span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{
                    colaborador.dt_nascimento
                  }}</span>
                </td>
                <td class="align-middle">
                  <a
                    href="javascript:;"
                    class="text-secondary font-weight-bold text-xs"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    >Visualizar</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BreadcrumbsColaborador from "@/components/Breadcrumbs.vue";

export default {
  components: { BreadcrumbsColaborador },
  mounted() {
    this.fetchColaborador();
  },
  data() {
    return {
      breadcrumbs: [{ label: "Home", to: "/dashboard-auto" }],
      colaboradores: [],
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    async fetchColaborador() {
      const token = this.$store.state.token;
      const id = this.$store.state.id_auto;

      console.log(token);
      try {
        const response = await axios.get(
          this.$baseURL +
            `/colaborador/buscaTodosColaboradoresAutoEscola/` +
            id,
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("---RESPONSE--", response);
        this.colaboradores = response.data.data;
      } catch (error) {
        console.error("Erro ao buscar alunos:", error);
      }
    },

    cadColaborador() {
      this.$router.push("/cad-colaborador");
    },
  },
};
</script>

<style scoped>
.input-field,
.search-btn,
.btn-new {
  height: 38px;
  border-radius: 0;
}

.select-field {
  width: 40px;
}

.search-input {
  flex-grow: 1;
}
</style>
