import AdminDashboard from "../../views/DashboardAdmin.vue";
import cadModalidade from "../admin/modalidades/cadModalidade.vue";
import listaModalidade from "../admin/modalidades/listaModalidade.vue";
import listaPacotes from "../admin/pacotes/listaPacotes.vue";
import cadPacotes from "../admin/pacotes/cadPacotes.vue";
import listaAuto from "../admin/auto_escolas/listaAuto.vue";
import listaCategoria from "../admin/categorias/listaCategorias";
import cadCategoria from "../admin/categorias/cadCategoria.vue";
import editaCategoria from "../admin/categorias/editaCategoria.vue";
import editaAuto from "../admin/auto_escolas/editaAuto.vue";

import editaCargo from "../admin/cargos/editaCargo.vue";
import editaModalidade from "../admin/modalidades/editaModalidade.vue";
import listaCargo from "../admin/cargos/listaCargos.vue";
import cadCargo from "../admin/cargos/cadCargo.vue";
import cadAuto from "../admin/auto_escolas/cadAuto.vue";
import cadDocumento from "../../views/components/DocumentosCard.vue";

import exibePacote from "../admin/pacotes/exibePacote.vue";
import editaPacote from "../admin/pacotes/editaPacote.vue";

const adminRoutes = [
  {
    name: "DashboadAdmin",
    path: "/dashboard-admin",
    component: AdminDashboard,
    meta: { requiresAuth: true, roles: ["ROLE_ADMIN"] },
  },
  {
    path: "/cad-modalidade",
    component: cadModalidade,
    meta: { requiresAuth: true, roles: ["ROLE_ADMIN"] },
  },
  {
    path: "/cad-categoria",
    component: cadCategoria,
    meta: { requiresAuth: true, roles: ["ROLE_ADMIN"] },
  },
  {
    path: "/edita-categoria/:id",
    component: editaCategoria,
    meta: { requiresAuth: true, roles: ["ROLE_ADMIN"] },
  },
  {
    path: "/edita-modalidade/:id",
    component: editaModalidade,
    meta: { requiresAuth: true, roles: ["ROLE_ADMIN"] },
  },
  {
    path: "/lista-modalidade",
    component: listaModalidade,
    meta: { requiresAuth: true, roles: ["ROLE_ADMIN"] },
  },
  {
    path: "/cad-cargo",
    component: cadCargo,
    meta: { requiresAuth: true, roles: ["ROLE_ADMIN"] },
  },
  {
    path: "/edita-cargo/:id",
    component: editaCargo,
    meta: { requiresAuth: true, roles: ["ROLE_ADMIN"] },
  },
  {
    path: "/edita-auto/:id",
    component: editaAuto,
    meta: { requiresAuth: true, roles: ["ROLE_ADMIN"] },
  },
  {
    path: "/lista-cargos",
    component: listaCargo,
    meta: { requiresAuth: true, roles: ["ROLE_ADMIN"] },
  },
  {
    path: "/lista-pacotes",
    component: listaPacotes,
    meta: { requiresAuth: true, roles: ["ROLE_ADMIN"] },
  },
  {
    path: "/lista-categoria",
    component: listaCategoria,
    meta: { requiresAuth: true, roles: ["ROLE_ADMIN"] },
  },
  {
    path: "/exibe-pacote/:id",
    name: "ExibePacote",
    component: exibePacote,
    meta: { requiresAuth: true, roles: ["ROLE_ADMIN"] },
  },
  {
    path: "/edita-pacote/:id",
    name: "EditaPacote",
    component: editaPacote,
    meta: { requiresAuth: true, roles: ["ROLE_ADMIN"] },
  },

  {
    path: "/cad-pacote",
    component: cadPacotes,
    meta: { requiresAuth: true, roles: ["ROLE_ADMIN"] },
  },
  {
    path: "/lista-auto",
    component: listaAuto,
    meta: { requiresAuth: true, roles: ["ROLE_ADMIN"] },
  },
  {
    path: "/cad-auto",
    component: cadAuto,
    meta: { requiresAuth: true, roles: ["ROLE_ADMIN"] },
  },
  {
    path: "/cad-documento/:tipoEmpresa/:galaxId/:galaxHash",
    component: cadDocumento,
    meta: { requiresAuth: true, roles: ["ROLE_ADMIN"] },
  },
];

export default adminRoutes;
