<template>
  <div class="card">
    <div class="pb-0 card-header mb-0">
      <h6>{{ title }}</h6>
      <p class="text-sm">
        <i class="fa fa-arrow-up text-success"></i>
        <span class="font-weight-bold">{{ detail1 }}</span>
        {{ detail2 }}
      </p>
    </div>
    <div class="p-3 card-body">
      <div class="chart">
        <canvas id="chart-line" class="chart-canvas" height="300"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "gradient-line-chart",

  props: {
    title: {
      type: String,
      default: "Gráfico de Vendas",
    },
    detail1: {
      type: String,
      default: "Em breve...",
    },
  },

  mounted() {},
};
</script>
