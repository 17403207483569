<template>
  <BreadcrumbsListaAlunos :breadcrumbs="breadcrumbs" :router="$router" />

  <div class="py-4 container-fluid">
    <div class="card">
      <div class="container mt-2">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12 d-flex align-items-center">
            <button class="btn btn-primary btn-new" @click="cadAluno">
              Novo Aluno
            </button>
          </div>
          <div class="col-lg-8 col-md-6 col-sm-12 d-flex align-items-center">
            <div class="d-flex w-90">
              <select class="form-control input-field" v-model="filtro">
                <option disabled selected hidden>Selecione uma opção</option>
                <option value="nome">Nome</option>
                <option value="cpf">CPF</option>
                <option value="email">E-mail</option>
              </select>

              <input type="text" class="form-control input-field" placeholder="Pesquisar" v-model="termoBusca" />
              <button class="btn btn-primary search-btn btn-new" @click="buscarAlunos">Ir</button>
            </div>
          </div>

        </div>
      </div>

      <div class="card-header pb-0">
        <h6>Lista de Alunos</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <v-breadcrumbs bg-color="primary" :items="['Foo', 'Bar', 'Fizz']"></v-breadcrumbs>
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Nome
                </th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  CPF
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Status
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Dt. Nascimento
                </th>
                <th class="text-secondary opacity-7"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="aluno in alunos" :key="aluno.id">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ aluno.user.nome }}</h6>
                      <p class="text-xs text-secondary mb-0">
                        {{ aluno.user.email }}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs text-secondary mb-0">{{ aluno.cpf }}</p>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="badge badge-sm bg-gradient-success">
                    {{ aluno.status }}
                  </span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{
    aluno.dt_nascimento
                    }}</span>
                </td>
                <td class="align-middle">
                  <a href="javascript:;" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip"
                    data-original-title="Edit user">Visualizar</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BreadcrumbsListaAlunos from "@/components/Breadcrumbs.vue";

export default {
  components: { BreadcrumbsListaAlunos },

  mounted() {
    this.fetchAlunos();
    this.filtro = this.$route.query.filtro || '';
    this.termoBusca = this.$route.query.termo || ''; 
  },
  data() {
    return {
      breadcrumbs: [{ label: "Home", to: "/dashboard-auto" }],
      alunos: [],
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    buscarAlunos() {
      const { filtro, termoBusca } = this;
      const routeParams = {};

      if (filtro && termoBusca) {
        routeParams[filtro] = termoBusca;
      }

      this.$router.push({ path: '/alunos', query: routeParams });
      this.fetchAlunos(); 
    },

    async fetchAlunos() {
      const token = this.$store.state.token;
      const id = this.$store.state.id_auto;
      const { nome, cpf, email } = this.$route.query; 

      try {
        const response = await axios.get(
          this.$baseURL + `/alunos/buscaAlunosPorAutoEscolaID/${id}`,
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "application/json",
            },
            params: { nome, cpf, email },
          }
        );

        this.alunos = response.data.data;
      } catch (error) {
        console.error("Erro ao buscar alunos:", error);
      }
    },



    cadAluno() {
      this.$router.push("/cad-alunos");
    },
  },
};
</script>

<style scoped>
.input-field,
.search-btn,
.btn-new {
  height: 38px;
  border-radius: 0;
}

.select-field {
  width: 40px;
}

.search-input {
  flex-grow: 1;
}
</style>
