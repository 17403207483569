<template>
  <BreadcrumbsPacote :breadcrumbs="breadcrumbs" :router="$router" />

  <div class="py-4 container-fluid">
    <div class="card">
      <div class="container mt-2">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12 d-flex align-items-center">
            <button class="btn btn-primary btn-new" @click="cadPacote">
              Novo Pacote
            </button>
          </div>
          <div class="col-lg-8 col-md-6 col-sm-12 d-flex align-items-center">
            <div class="d-flex w-90">
              <select class="form-control input-field">
                <option disabled selected hidden>Selecione uma opção</option>
                <option value="Nome">Nome</option>
                <option value="CPF">CPF</option>
                <option value="E-mail">E-mail</option>
              </select>

              <input
                type="text"
                class="form-control input-field"
                placeholder="Pesquisar"
              />
              <button class="btn btn-primary search-btn btn-new">Ir</button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-header pb-0">
        <h6>Lista de Pacotes</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <v-breadcrumbs
            bg-color="primary"
            :items="['Foo', 'Bar', 'Fizz']"
          ></v-breadcrumbs>
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Curso
                </th>
                <th
                  class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                >
                  Qtd. UPF H/Aula
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Valor Unit. H/Aula
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Carga Horária
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Categoria
                </th>
                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Modalidade
                </th>

                <th
                  class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                >
                  Total
                </th>

                <th class="text-secondary opacity-7"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="produto in produtos" :key="produto.id">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ produto.curso }}</h6>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs text-secondary mb-0">
                    {{ produto.upf }}
                  </p>
                </td>
                <td class="align-middle text-center text-sm">
                  <p class="text-xs text-secondary mb-0">
                    R$ {{ produto.val_unitario }}
                  </p>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{
                    produto.carga_horaria
                  }}</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{
                    produto.categoria.nome
                  }}</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{
                    produto.modalidade.nome
                  }}</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold"
                    >R$ {{ produto.val_total }}</span
                  >
                </td>
                <td class="align-right">
                  <a
                    href="javascript:;"
                    class="text-primary font-weight-bold text-xs"
                    @click="abrirPacote(produto.id)"
                    data-toggle="tooltip"
                    data-original-title="Abrir pacote"
                    >Abrir</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BreadcrumbsPacote from "@/components/Breadcrumbs.vue";

export default {
  name: "ListaProdutosComponents",
  components: { BreadcrumbsPacote },

  mounted() {
    this.fetchProdutos();
  },
  data() {
    return {
      breadcrumbs: [{ label: "Home", to: "/dashboard-admin" }],
      produtos: [],
    };
  },
  methods: {
    abrirPacote(id) {
      this.$router.push({ name: "ExibePacote", params: { id } });
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    async fetchProdutos() {
      const token = this.$store.state.token;

      try {
        const response = await axios.get(
          this.$baseURL + `/produto/buscaProduto/`,
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("---RESPONSE--", response);
        this.produtos = response.data.data;
      } catch (error) {
        console.error("Erro ao buscar produtos:", error);
      }
    },

    cadPacote() {
      this.$router.push("/cad-pacote");
    },
  },
};
</script>

<style scoped>
.input-field,
.search-btn,
.btn-new {
  height: 38px; /* Defina a mesma altura para todos os elementos */
  border-radius: 0;
}

.select-field {
  width: 40px; /* Defina a largura desejada para o select */
}

.search-input {
  flex-grow: 1; /* O input vai ocupar todo o espaço restante */
}
</style>
