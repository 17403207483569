<template>
  <BreadcrumbsCadCategoria :breadcrumbs="breadcrumbs" :router="$router" />

  <main>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="cadCategoria">
                <div class="row">
                  <div class="col-md-12">
                    <label for="example-text-input" class="form-control-label"
                      >Nome</label
                    >
                    <input
                      v-model="nome"
                      required
                      class="form-control mb-1"
                      type="text"
                      placeholder="Ex: Categoria A"
                    />
                  </div>
                </div>

                <argon-button
                  color="success"
                  size="sm"
                  class="ms-auto mt-2"
                  @click="cadCategoria"
                  :disabled="submitting"
                  >Salvar</argon-button
                >
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import ArgonButton from "@/components/ArgonButton.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import BreadcrumbsCadCategoria from "@/components/Breadcrumbs.vue";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "CadCategoriaComponent",
  components: { ArgonButton, BreadcrumbsCadCategoria },

  data() {
    return {
      breadcrumbs: [
        { label: "Home", to: "/dashboard-admin" },
        { label: "Lista Categorias", to: "/lista-categoria" },
      ],

      nome: "",
      submitting: false,
    };
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    async cadCategoria() {
      if (!this.nome || this.submitting) {
        return;
      }
      this.submitting = true;

      try {
        const toaster = createToaster({});
        const token = this.$store.state.token;

        const dados = {
          nome: this.nome,
        };

        try {
          const response = await axios.post(
            this.$baseURL + "/categoria/novaCategoria",
            dados,
            {
              headers: {
                "x-access-token": `${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (response.status === 200) {
            toaster.success("Categoria cadastrada com sucesso!", {
              timeout: 2000,
            });

            this.$router.push("/lista-categoria");
          } else {
            console.log(response.data.message);
            toaster.success(response.data.message, {
              timeout: 4000,
            });
          }
        } catch (error) {
          console.log(error);
          console.error("Error:", error);
        }
      } finally {
        this.submitting = false;
      }
    },
  },

  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip();
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  },
};
</script>
