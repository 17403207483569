<template>
  <BreadcrumbsAlteraSenha :breadcrumbs="breadcrumbs" :router="$router" />

  <main>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p class="mb-0">Alterar Senha</p>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <label for="novaSenha" class="form-control-label">Nova Senha</label>
                  <input type="password" required class="form-control" v-model="novaSenha" />
                </div>
                <div class="col-md-6">
                  <label for="repetirSenha" class="form-control-label">Repetir Senha</label>
                  <input type="password" required class="form-control" v-model="repetirSenha" />
                </div>
              </div>
              <hr class="horizontal dark" />
              <argon-button color="success" size="sm" class="ms-auto" @click="alterarSenha">Salvar</argon-button>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <profile-card />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsAlteraSenha from "@/components/Breadcrumbs.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { createToaster } from "@meforma/vue-toaster";
import axios from "axios";

export default {
  name: "ProfileComponent",
  data() {
    return {
      breadcrumbs: [
        { label: "Home", to: "/dashboard-auto" },
      ],
      novaSenha: '',
      repetirSenha: ''
    };
  },
  components: { ArgonButton, BreadcrumbsAlteraSenha },

  methods: {
    async alterarSenha() {
      const toaster = createToaster({});

      console.log('novaSenha:', this.novaSenha);
      console.log('repetirSenha:', this.repetirSenha);

      if (!this.novaSenha || this.novaSenha !== this.repetirSenha) {
        toaster.error('As senhas não coincidem ou não foram preenchidas corretamente', {
          timeout: 4000,
        });
        return;
      }

      try {
        const token = this.$store.state.token;
        const id = this.$store.state.id_user;

        const body = {
          novaSenha: this.novaSenha,
        };

        const response = await axios.put(
          this.$baseURL + `/usuario/alteraSenha/${id}`,
          body,
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          toaster.success("Senha alterada com sucesso!", {
            timeout: 2000,
          });

          this.$router.push("/dashboard-auto");
        } else {
          let errorMessage = response.data.message;
          console.log(errorMessage);
          toaster.error(errorMessage, {
            timeout: 4000,
          });
        }
      } catch (error) {
        console.error('Erro ao alterar senha:', error);
        toaster.error('Erro ao alterar senha. Por favor, tente novamente.', {
          timeout: 4000,
        });
      }
    }
  }
};
</script>
