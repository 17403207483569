import { createStore } from "vuex";

export default createStore({
  state: {
    id_auto: "",
    token: "",
    id_user: "",
    cod_efi: "",
    userRole: "",
    isAuthenticated: false,
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-white",
    isRTL: false,
    mcolor: "",
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: "default",
  },

  mutations: {
    clearAuth(state) {
      state.isAuthenticated = false;
      state.userRole = null;
      state.id_auto = null;
      state.token = null;
      state.id_user = null;
    },
    setCodEfi(state, cod_efi) {
      state.cod_efi = cod_efi;
    },

    setIsAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    setIdAuto(state, id_auto) {
      state.id_auto = id_auto;
    },
    setToken(state, token) {
      state.token = token;
    },
    setUserRole(state, role) {
      state.userRole = role;
    },
    setIdUsuario(state, id_user) {
      state.id_user = id_user;
    },
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      state.navbarMinimized = !state.navbarMinimized;
    },
    sidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
  },
  actions: {
    logout({ commit }) {
      commit("clearAuth");
    },
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
  },
  getters: {},
});
