<template>
  <BreadcrumbsExibeBreadCrumbs :breadcrumbs="breadcrumbs" :router="$router" />

  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6 mt-3 mb-4">
        <div class="card pb-4">
          <div class="card-header pb-0 p-3">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <h6 class="mb-0">Dados do Pagamento</h6>
              </div>
            </div>
          </div>
          <div class="card-body p-3 pb-0 mb-0">
            <ul class="list-group">
              <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                v-for="cobranca in cobrancas" :key="cobranca.id">
                <div class="d-flex flex-column">
                  <h6 class="mb-1 text-dark font-weight-bold text-sm">
                    Link de Pagamento
                  </h6>
                  <a :href="cobranca.link_pagamento" target="_blank" rel="noopener noreferrer">
                    <span class="text-xs">{{ cobranca.link_pagamento }}</span>
                  </a>
                </div>


              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6 mt-3 mb-2" v-for="cobranca in cobrancas" :key="cobranca.id">
        <div class="card pb-4">
          <div class="card-header pb-0 px-3">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <h6 class="mb-0">Status da Compra:</h6>
              </div>
              <div class="col-md-6 d-flex justify-content-end align-items-center">
                <small
                    class="badge badge-sm"
                    v-bind:class="getBadgeClasses(cobranca.status)"
                  >
                    {{ formatarStatus(cobranca.status) }}</small>
              </div>
            </div>
          </div>
          <div class="card-body p-1 pb-0 mb-0">
            <ul class="list-group">
              <li class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                <div class="d-flex align-items-center">
                  <argon-button color="success" variant="outline" size="sm"
                    class="btn-icon-only btn-rounded mb-0 me-3 d-flex align-items-center justify-content-center">
                  </argon-button>
                  <div class="d-flex flex-column">
                    <h6 class="text-dark text-sm">Valor Total: R$ {{ cobranca.total }} </h6>
                    <span class="text-xs">Pago através de: {{ statusPagamento(cobranca.tipo_pagamento) }}</span>
                  </div>
                </div>

              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-for="cobranca in cobrancas" :key="cobranca.id">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header pb-0 p-3">
            <h6 class="mb-0">Informações do Aluno</h6>
          </div>
          <div class="card-body pt-4 p-3">
            <ul class="list-group">
              <li class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg">
                <div class="col-md-6">
                  <div class="d-flex flex-column">
                    <h6 class="mb-3 text-sm">{{ cobranca.user.nome }}</h6>
                    <span class="mb-2 text-xs">
                      E-mail:
                      <span class="text-dark font-weight-bold ms-sm-2">{{
    cobranca.user.email
  }}</span>
                    </span>
                    <span class="mb-2 text-xs">
                      Telefone
                      <span class="text-dark ms-sm-2 font-weight-bold">{{
      cobranca.user.telefone
    }}</span>
                    </span>
                    <span class="text-xs">
                      CPF:
                      <span class="text-dark ms-sm-2 font-weight-bold">{{
      cobranca.user.aluno.cpf
    }}</span>
                    </span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="d-flex flex-column">
                    <h6 class="mb-3 text-sm"></h6>
                    <span class="mb-2 text-xs">
                      CEP:
                      <span class="text-dark font-weight-bold ms-sm-2">{{
      cobranca.user.endereco.cep
                        }}</span>
                    </span>
                    <span class="mb-2 text-xs">
                      Logradouro:
                      <span class="text-dark ms-sm-2 font-weight-bold">{{
                        cobranca.user.endereco.logradouro
                        }}</span>
                    </span>
                    <span class="text-xs">
                      Cidade/UF:
                      <span class="text-dark ms-sm-2 font-weight-bold">{{ cobranca.user.endereco.cidade }} -
                        {{ cobranca.user.endereco.estado }}</span>
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BreadcrumbsExibeBreadCrumbs from "@/components/Breadcrumbs.vue";

export default {
  components: { BreadcrumbsExibeBreadCrumbs },

  data() {
    return {
      breadcrumbs: [
        { label: "Home", to: "/dashboard-auto" },
        { label: "Movimentação", to: "/lista-cobranca" },
      ],
      cobrancas: [],
    };
  },
  mounted() {
    this.obterDetalhesCobranca();
  },
  methods: {
    statusPagamento(status) {
      switch (status) {
        case "creditcard":
          return "Cartão de Crédito";
        case "boleto":
          return "Boleto Bancário";
        default:
          return status;
      }
    },
    formatarStatus(status) {
      switch (status) {
        case "active":
          return "Ativa";
        case "canceled":
          return "Cancelada";
        case "closed":
          return "Encerrada";
        case "waitingPayment":
          return "Aguardando Pagamento";
        case "inactive":
          return "Inativa";
        default:
          return status;
      }
    },
    getBadgeClasses(status) {
      switch (status) {
        case "active":
          return "bg-gradient-primary";
        case "canceled":
          return "bg-gradient-danger";
        case "closed":
          return "bg-gradient-info";
        case "waitingPayment":
          return "bg-gradient-purple";
        case "inactive":
          return "bg-gradient-dark";
        default:
          return "";
      }
    },
    async obterDetalhesCobranca() {
      const token = this.$store.state.token;
      console.log(this.$route.params.autoId);

      console.log(token);
      try {
        const response = await axios.get(
          this.$baseURL +
          `/cobranca/exibeCobrancaPorId/${this.$route.params.id}/${this.$route.params.autoId}`,
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        this.cobrancas = response.data.data;
      } catch (error) {
        console.error("Erro ao buscar cobranca:", error);
      }
    },
  },
};
</script>
