import AutoDashboard from "../../views/DashboardAuto.vue";
import cadAluno from "../../domain/auto_escola/alunos/cadAluno.vue";
import listaAlunos from "../../domain/auto_escola/alunos/listaAlunos.vue";
import gerarCobranca from "../../domain/auto_escola/cobrancas/gerarCobranca.vue";
import listaCobranca from "../../domain/auto_escola/cobrancas/listaCobranca.vue";
import exibeCobranca from "../../domain/auto_escola/cobrancas/exibeCobranca.vue";
import listaColaborador from "../../domain/auto_escola/colaboradores/listaColaborador.vue";
import cadColaborador from "../../domain/auto_escola/colaboradores/cadColaborador.vue";

const autoRoutes = [
  {
    name: "DashboadAuto",
    path: "/dashboard-auto",
    component: AutoDashboard,
    meta: { requiresAuth: true, roles: ["ROLE_AUTO_ESCOLA"] },
  },
  {
    path: "/gerar-cobranca",
    component: gerarCobranca,
    meta: { requiresAuth: true, roles: ["ROLE_AUTO_ESCOLA", "ROLE_FUNCIONARIO"] },
  },
  {
    path: "/lista-cobranca",
    component: listaCobranca,
    meta: { requiresAuth: true, roles: ["ROLE_AUTO_ESCOLA"] },
  },
  {
    path: "/exibe-cobranca/:id/:autoId",
    component: exibeCobranca,
    meta: { requiresAuth: true, roles: ["ROLE_AUTO_ESCOLA"] },
    name: "exibeCobranca",
  },
  {
    path: "/cad-alunos",
    component: cadAluno,
    meta: { requiresAuth: true, roles: ["ROLE_AUTO_ESCOLA", "ROLE_FUNCIONARIO"] },
  },
  {
    path: "/alunos",
    component: listaAlunos,
    meta: {
      breadcrumb: "Alunos",
      requiresAuth: true,
      roles: ["ROLE_AUTO_ESCOLA", "ROLE_FUNCIONARIO"],
    },
  },
  {
    path: "/lista-colaboradores",
    component: listaColaborador,
    meta: {
      breadcrumb: "Colaborador",
      requiresAuth: true,
      roles: ["ROLE_AUTO_ESCOLA"],
    },
  },
  {
    path: "/cad-colaborador",
    component: cadColaborador,
    meta: {
      breadcrumb: "Colaborador",
      requiresAuth: true,
      roles: ["ROLE_AUTO_ESCOLA"],
    },
  },
];

export default autoRoutes;
