<template>
  <div v-show="this.$store.state.layout === 'landing'" class="landing-bg h-80 bg-gradient-primary position-fixed w-40">
  </div>

  <template v-if="isAuthenticated && userRole === 'ROLE_AUTO_ESCOLA'">
    <sidenav-auto :custom_class="this.$store.state.mcolor" :class="[
    this.$store.state.isTransparent,
    this.$store.state.isRTL ? 'fixed-end' : 'fixed-start',
  ]" v-if="this.$store.state.showSidenav" />
  </template>

  <template v-if="isAuthenticated && userRole === 'ROLE_FUNCIONARIO'">
    <sidenav-colaborador :custom_class="this.$store.state.mcolor" :class="[
    this.$store.state.isTransparent,
    this.$store.state.isRTL ? 'fixed-end' : 'fixed-start',
  ]" v-if="this.$store.state.showSidenav" />
  </template>

  <template v-if="isAuthenticated && userRole === 'ROLE_ADMIN'">
    <sidenav-admin :custom_class="this.$store.state.mcolor" :class="[
    this.$store.state.isTransparent,
    this.$store.state.isRTL ? 'fixed-end' : 'fixed-start',
  ]" v-if="this.$store.state.showSidenav" />
  </template>
  <main class="main-content position-relative max-height-vh-100 h-80 border-radius-lg">
    <template v-if="showNavbar">
      <template v-if="isAuthenticated && userRole === 'ROLE_AUTOESCOLA'">
        <SidenavAuto />
      </template>
      <template v-if="isAuthenticated && userRole === 'ROLE_ADMIN'">
        <SidenavAdmin />
      </template>
    </template>
    <router-view />
    <app-footer v-show="this.$store.state.showFooter" />
    <template v-if="isAuthenticated && userRole === 'ROLE_ADMIN'">
      <configurator :toggle="toggleConfigurator" :class="[
    this.$store.state.showConfig ? 'show' : '',
    this.$store.state.hideConfigButton ? 'd-none' : '',
  ]" />
    </template>

  </main>
</template>
<script>
import { ref } from "vue";
import SidenavAuto from "./domain/auto_escola/components/Sidenav";
import SidenavAdmin from "./domain/admin/components/Sidenav";
import SidenavColaborador from "./domain/colaborador/components/Sidnav/index.vue";


import Configurator from "@/examples/Configurator.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations, mapState } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "App",
  beforeCreate() {
    const router = useRouter();
    const currentRoute = ref(router.currentRoute.value);

    // Acesse o objeto router aqui, após sua criação
    this.$router.beforeEach((to, from, next) => {
      // Verifique se a rota existe
      if (to.matched.length === 0) {
        router.push({ path: currentRoute.value.path });

        const redirectToSamePage = () => {
          router.push({ path: currentRoute.value.path });
        };

        return {
          redirectToSamePage,
        };

        // Rota não encontrada, redirecione para uma página de erro
      } else {
        // Rota válida, continue a navegação
        next();
      }
    });
  },
  components: {
    SidenavAuto,
    SidenavAdmin,
    SidenavColaborador,
    Configurator,
    AppFooter,
  },
  methods: {
    ...mapMutations(["toggleConfigurator"]),
    ...mapMutations({ navbarMinimize: "navbarMinimize" }),
  },
  computed: {
    ...mapState(["showNavbar", "isAuthenticated", "userRole"]),

    navClasses() {
      return {
        "position-sticky bg-white left-auto top-2 z-index-sticky":
          this.$store.state.isNavFixed && !this.$store.state.darkMode,
        "position-sticky bg-default left-auto top-2 z-index-sticky":
          this.$store.state.isNavFixed && this.$store.state.darkMode,
        "position-absolute px-4 mx-0 w-100 z-index-2":
          this.$store.state.isAbsolute,
        "px-0 mx-4": !this.$store.state.isAbsolute,
      };
    },
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-transparent";
  },
};
</script>
