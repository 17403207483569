<template>
  <BreadcrumbsGerarCobranca :breadcrumbs="breadcrumbs" :router="$router" />

  <div class="py-4 container-fluid">
    <div class="d-flex justify-content-left mb-3">
      <button @click="showBoletoSection"
        :class="{ 'btn-primary': currentSection === 'boleto', 'btn-secondary': currentSection !== 'boleto' }"
        class="btn mr-2">
        <i class="fas fa-barcode"></i> Boleto
      </button>
      <button @click="showCreditoSection"
        :class="{ 'btn-primary': currentSection === 'credito', 'btn-secondary': currentSection !== 'credito' }"
        class="btn">
        <i class="far fa-credit-card"></i> Cartão de Crédito
      </button>
    </div>
    <div class="card">
      <div v-if="currentSection === 'boleto'" class="container mt-2">
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <label for="search" class="form-control-label">Buscar Aluno (mínimo 3 letras)</label>
                <div class="input-group">
                  <input type="text" v-model="searchText" id="search" class="form-control" style="height: 40px;">
                  <div class="input-group-append">
                    <button @click="fetchAlunos" class="btn btn-primary">Pesquisar</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <select v-model="selectedAluno" class="form-control">
                  <option v-for="aluno in alunos" :key="aluno.id" :value="aluno">{{ aluno.user.nome }}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <label for="example-text-input" class="form-control-label">Pacote ou Serviço</label>
            <select v-model="selectedProduto" required class="form-control">
              <option v-for="produto in produtos" :key="produto.id" :value="produto">{{ produto.produto.curso }}
              </option>
            </select>
          </div>
        </div>
        <div class="mt-2">
          <button @click="gerarCobranca" class="btn btn-success">Gerar boleto</button>
          <b-spinner v-if="isLoading" label="Carregando..."></b-spinner>
        </div>
      </div>

      <div v-if="currentSection === 'credito'" class="container mt-2">
        <div class="row">
          <!-- Coluna esquerda  -->
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <label for="search" class="form-control-label">Buscar Aluno (mínimo 3 letras)</label>
                <div class="input-group">
                  <input type="text" v-model="searchText" id="search" class="form-control" style="height: 40px;">
                  <div class="input-group-append">
                    <button @click="fetchAlunos" class="btn btn-primary">Pesquisar</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-12">
                <select v-model="selectedAluno" class="form-control">
                  <option v-for="aluno in alunos" :key="aluno.id" :value="aluno">{{ aluno.user.nome }}</option>
                </select>
              </div>
            </div>
          </div>

          <!-- Coluna direita -->
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6">
                <label for="example-text-input" class="form-control-label">Pacote ou Serviço</label>
                <select v-model="selectedProduto" required class="form-control">
                  <option v-for="produto in produtos" :key="produto.id" :value="produto">{{ produto.produto.curso }}
                  </option>
                </select>
              </div>
              <div class="col-md-6">
                <label for="example-text-input" class="form-control-label">Qtd de parcelas</label>
                <select v-model="numeroParcelas" required class="form-control">
                  <option value="1">1 parcela</option>
                  <option v-for="n in 11" :key="n + 1" :value="n + 1">{{ n + 1 }} parcela(s)</option>
                </select>
              </div>
            </div>
            <hr>
            <label for="example-text-input" class="form-control-label">Acréscimo</label>
            <div class="row mt-2">
              <div class="col-md-6">
                <div class="form-check mt-3">
                  <input class="form-check-input" type="checkbox" id="acrescimoCheckbox" v-model="desejaAcrescimo">
                  <label class="form-check-label" for="acrescimoCheckbox">
                    Deseja adicionar um acréscimo ao valor final do serviço?
                  </label>
                </div>
              </div>
              <div class="col-md-6" v-if="desejaAcrescimo">
                <label for="example-text-input" class="form-control-label">Informe o valor do acréscimo</label>
                <money3 v-model="valorAcrescimo" class="form-control mb-1" type="text" v-bind="config" required />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-6">
            <button @click="gerarCobranca" class="btn btn-success">Gerar link de pagamento</button>
            <b-spinner v-if="isLoading" label="Carregando..."></b-spinner>
          </div>
        </div>
      </div>


    </div>
  </div>
  <loading-overlay :active="isLoading" :fixed="true" :can-cancel="true" @cancel="onCancel"></loading-overlay>
</template>

<script>
import axios from "axios";
import LoadingOverlay from "vue-loading-overlay";
import BreadcrumbsGerarCobranca from "@/components/Breadcrumbs.vue";
import { createToaster } from "@meforma/vue-toaster";
import { Money3Component } from "v-money3";


export default {
  name: "CobrancaForm",
  components: {
    LoadingOverlay,
    BreadcrumbsGerarCobranca,
    money3: Money3Component,
  },
  data() {
    return {
      config: {
        masked: false,
        prefix: "R$ ",
        suffix: "",
        thousands: ".",
        decimal: ",",
        precision: 2,
        disableNegative: false,
        disabled: false,
        min: null,
        max: null,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
        shouldRound: true,
        focusOnRight: false,
      },
      numeroParcelas: '1',
      desejaAcrescimo: false,
      breadcrumbs: [{ label: "Home", to: "/dashboard-auto" }],
      isLoading: false,
      searchText: '',
      alunos: [],
      selectedAluno: null,
      produtos: [],
      alunoSelecionado: null,
      selectedProduto: null,
      currentSection: 'boleto',
      nomeAlunoDigitado: '',
      nomeInvalido: false
    };
  },
  filteredAlunos() {
    return this.alunos.filter(aluno => {
      return aluno.user.nome.toLowerCase().includes(this.searchText.toLowerCase());
    });
  },
  methods: {
    getResultValue(aluno) {
      if (aluno && aluno.user) {
        return aluno.user.nome;
      }
      return '';
    },
    searchAlunos(query) {
      if (!query) return [];
      return this.alunos.filter(aluno => aluno.user.nome.toLowerCase().includes(query.toLowerCase()));
    },

    showBoletoSection() {
      this.currentSection = 'boleto';
    },
    showCreditoSection() {
      this.currentSection = 'credito';
    },
    handleAlunoSelecionado(alunoSelecionado) {
      this.alunoSelecionado = alunoSelecionado;
    },
    async fetchAlunos() {
      const toaster = createToaster({});

      if (this.searchText.length < 3) {
        toaster.error(
          "Insira pelo menos 3 letras para buscar.",
          {
            timeout: 3000,
          }
        );
        return;
      }

      const token = this.$store.state.token;
      const id = this.$store.state.id_auto;

      try {
        const response = await axios.get(
          this.$baseURL + `/alunos/buscaTodosAlunosAutoEscola/` + id,
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "application/json",
            },
            params: {
              search: this.searchText
            }
          }
        );
        this.alunos = response.data.data;

        if (response.data.data.length > 0) {
          this.selectedAluno = response.data.data[0];
        } else {
          this.selectedAluno = null;
        }
      } catch (error) {
        console.error("Erro ao buscar alunos:", error);
      }
    },
    async fetchProdutos() {
      const token = this.$store.state.token;
      const id_user = this.$store.state.id_user;

      try {
        const response = await axios.get(
          this.$baseURL + `/produto/buscaProdutosAutoEscola/` + id_user,
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        this.produtos = response.data.data;

        if (response.data.data.length > 0) {
          this.selectedProduto = response.data.data[0];
        }
      } catch (error) {
        console.error("Erro ao buscar produtos:", error);
      }
    },
    async gerarCobranca() {
      const toaster = createToaster({});

      if (!this.selectedAluno || !this.selectedProduto) {
        toaster.error(
          "Por favor, selecione um aluno antes de gerar a cobrança.",
          {
            timeout: 2000,
          }
        );
        return;
      }

      if (!this.selectedProduto) {
        toaster.error(
          "Por favor, selecione um produto antes de gerar a cobrança.",
          {
            timeout: 2000,
          }
        );
        return;
      }
      const token = this.$store.state.token;
      const id_auto = this.$store.state.id_auto;

      try {
        this.isLoading = true;
        let cobrancaData;

        console.log('VALOR ACRÉSCIMO---', this.valorAcrescimo)

        if (this.currentSection == "credito") {
          let preco = parseFloat(this.selectedProduto.produto.val_total);
          if (this.desejaAcrescimo && this.valorAcrescimo > 0) {
            preco += parseFloat(this.valorAcrescimo); // Converte valorAcrescimo para um número e adiciona ao preco
          }

          console.log('preco---', preco);


          cobrancaData = {
            mainPaymentMethodId: "credicard",
            nome: this.selectedAluno.user.nome,
            email: this.selectedAluno.user.email,
            cpf: this.selectedAluno.cpf,
            qtdInstallments: this.numeroParcelas,
            dt_aniversario: this.selectedAluno.dt_nascimento,
            telefone: this.selectedAluno.user.telefone,
            titulo: this.selectedProduto.produto.curso,
            preco: preco, // Usa o preço calculado
            userId: this.selectedAluno.user.id,
            autoId: id_auto,
          };
          console.log('----cobranca---', cobrancaData)
        } else {
          cobrancaData = {
            mainPaymentMethodId: "boleto",
            nome: this.selectedAluno.user.nome,
            email: this.selectedAluno.user.email,
            cpf: this.selectedAluno.cpf,
            dt_aniversario: this.selectedAluno.dt_nascimento,
            telefone: this.selectedAluno.user.telefone,
            titulo: this.selectedProduto.produto.curso,
            preco: this.selectedProduto.produto.val_total,
            userId: this.selectedAluno.user.id,
            autoId: id_auto,
          };
        }

        const response = await axios.post(
          this.$baseURL + "/cobranca/novaCobranca",
          cobrancaData,
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Cobrança gerada com sucesso:", response.data);
        toaster.success(
          "Cobrança gerada com sucesso.",
          {
            timeout: 2000,
          }
        );
        this.selectedAluno = null;
        this.selectedProduto = null;
        this.$router.push("/lista-cobranca");
      } catch (error) {
        console.error("Erro ao gerar cobrança:", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.fetchAlunos();
    this.fetchProdutos();
  },
};
</script>
