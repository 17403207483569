<template>
  <BreadcrumbsCargos :breadcrumbs="breadcrumbs" :router="$router" />

  <div class="py-4 container-fluid">
    <div class="card">
      <div class="container mt-2">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12 d-flex align-items-center">
            <button class="btn btn-primary btn-new" @click="cadCargo">
              Novo Cargo
            </button>
          </div>
        </div>
      </div>

      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <v-breadcrumbs bg-color="primary" :items="['Foo', 'Bar', 'Fizz']"></v-breadcrumbs>
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Nome
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="cargo in cargos" :key="cargo.id">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ cargo.nome }}</h6>
                    </div>
                  </div>
                </td>
                <td class="align-right">
                  <a href="javascript:;" class="text-primary font-weight-bold text-xs" @click="editarCargo(cargo.id)"
                    data-toggle="tooltip" data-original-title="Abrir cargo">Editar</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BreadcrumbsCargos from "@/components/Breadcrumbs.vue";

export default {
  components: { BreadcrumbsCargos },
  name: "BreadcrumbsCargosComponent",
  mounted() {
    this.fetchCargos();
  },
  data() {
    return {
      breadcrumbs: [{ label: "Home", to: "/dashboard-admin" }],
      cargos: [],
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    editarCargo(id) {
      this.$router.push({ path: `/edita-cargo/${id}` });
    },
    async fetchCargos() {
      const token = this.$store.state.token;

      try {
        const response = await axios.get(
          this.$baseURL + `/cargo/buscaCargos/`,
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        this.cargos = response.data.data;
      } catch (error) {
        console.error("Erro ao buscar cargos:", error);
      }
    },

    cadCargo() {
      this.$router.push("/cad-cargo");
    },
  },
};
</script>

<style scoped>
.input-field,
.search-btn,
.btn-new {
  height: 38px;
  /* Defina a mesma altura para todos os elementos */
  border-radius: 0;
}

.select-field {
  width: 40px;
  /* Defina a largura desejada para o select */
}

.search-input {
  flex-grow: 1;
  /* O input vai ocupar todo o espaço restante */
}
</style>
