import { createRouter, createWebHistory } from "vue-router";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import RTL from "../views/Rtl.vue";
import AlterarSenha from "../views/AlterarSenha.vue";
import Signup from "../views/Signup.vue";
import Login from "../views/Login.vue";
import ResetSenha from "../views/ResetSenha.vue";

import autoRoutes from "../domain/auto_escola/routes";
import adminRoutes from "@/domain/admin/routes";
import colaboradorRoutes from "@/domain/colaborador/routes"
import store from "@/store"; // Importe o store Vuex

const routes = [
  { path: "/:pathMatch(.*)*", redirect: "/login" },

  ...autoRoutes,
  ...adminRoutes,
  ...colaboradorRoutes,

  {
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },
  {
    path: "/alterar-senha",
    name: "AlterarSenha",
    component: AlterarSenha,
  },
  {
    path: "/reset-senha",
    name: "ResetSenha",
    component: ResetSenha,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.isAuthenticated;
  const userRole = store.state.userRole;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else if (!to.meta.roles.includes(userRole)) {
      console.log("ENTROU NA PÁGINA DE NÃO AUTORIZADO");
      next("/unauthorized");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
