<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          url="/dashboard-admin"
          :class="getRoute() === 'dashboard-admin' ? 'active' : ''"
          :navText="this.$store.state.isRTL ? 'لوحة القيادة' : 'Dashboard'"
        >
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          url="/lista-auto"
          :class="getRoute() === 'tables' ? 'active' : ''"
          :navText="this.$store.state.isRTL ? 'الجداول' : 'Auto Escolas'"
        >
          <template v-slot:icon>
            <i class="ni ni-bus-front-12 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          url="/lista-categoria"
          :class="getRoute() === 'lista-categoria' ? 'active' : ''"
          :navText="this.$store.state.isRTL ? 'الواقع الافتراضي' : 'Categorias'"
        >
          <template v-slot:icon>
            <i class="ni ni-bullet-list-67 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/lista-modalidade"
          :class="getRoute() === 'virtual-reality' ? 'active' : ''"
          :navText="
            this.$store.state.isRTL ? 'الواقع الافتراضي' : 'Modalidades'
          "
        >
          <template v-slot:icon>
            <i class="ni ni-books text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          url="/lista-pacotes"
          :class="getRoute() === 'virtual-reality' ? 'active' : ''"
          :navText="this.$store.state.isRTL ? 'الواقع الافتراضي' : 'Pacotes / Serviços'"
        >
          <template v-slot:icon>
            <i class="ni ni-tag text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <hr class="horizontal dark" />
      <h6
        class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6 mt-1"
        :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'"
      >
        Funcionário
      </h6>
      <li class="nav-item">
        <sidenav-item
          url="/lista-cargos"
          :class="getRoute() === 'virtual-reality' ? 'active' : ''"
          :navText="this.$store.state.isRTL ? 'الواقع الافتراضي' : 'Cargos'"
        >
          <template v-slot:icon>
            <i class="ni ni-badge text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <hr class="horizontal dark" />

      <h6
        class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6 mt-3"
        :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'"
      >
        Financeiro
      </h6>

      <li class="nav-item">
        <sidenav-item
          url=""
          :class="getRoute() === '' ? 'active' : ''"
          navText="Movimentação"
        >
          <template v-slot:icon>
            <i class="ni ni-chart-bar-32 text-danger text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Argon Dashboard 2",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  components: {
    SidenavItem,
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>
