<template>
  <nav
    class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
    :class="isRTL ? 'top-0 position-sticky z-index-sticky' : ''"
  >
    <div class="px-3 py-1 container-fluid">
      <div class="d-flex align-items-center justify-content-between w-100">
        <div class="breadcrumb m-0">
          <router-link
            v-for="(breadcrumb, index) in breadcrumbs"
            :key="index"
            :to="breadcrumb.to"
            class="breadcrumb-item"
            aria-current="page"
          >
            {{ breadcrumb.label }}
          </router-link>
        </div>
        <ul class="navbar-nav">
          <li class="px-3 nav-item d-flex align-items-center">
            <a class="p-0 nav-link text-white" @click="logout">
              <i
                class="cursor-pointer fa fa-sign-out fixed-plugin-button-nav"
              ></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "BreadcrumbsComponent",
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
    isRTL: {
      type: Boolean,
      default: false,
    },
    router: {
      type: Object,
      required: true,
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
  },
};
</script>
