<template>
  <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" :class="this.$store.state.isRTL ? 'top-0 position-sticky z-index-sticky' : ''
                                    " v-bind="$attrs" id="navbarBlur" data-scroll="true">
    <div class="px-3 py-1 container-fluid">
      <div class="d-flex align-items-center justify-content-between w-100">
        <div class="breadcrumb m-0">
          <router-link v-for="(breadcrumb, index) in breadcrumbs" :key="index" :to="breadcrumb.to"
            class="breadcrumb-item" aria-current="page">
            {{ breadcrumb.label }}
          </router-link>
        </div>
        <ul class="navbar-nav">
          <li class="px-3 nav-item d-flex align-items-center">
            <a class="p-0 nav-link text-white" @click="logout">
              <i class="cursor-pointer fa fa-sign-out fixed-plugin-button-nav"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div class="py-4 container-fluid">
    <div class="card">
      <div class="container mt-2">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12 d-flex align-items-center">
            <button class="btn btn-primary btn-new" @click="cadAuto">
              Nova Auto Escola
            </button>
          </div>
          <div class="col-lg-8 col-md-6 col-sm-12 d-flex align-items-center">
            <div class="d-flex w-90">
              <select class="form-control input-field">
                <option disabled selected hidden>Selecione uma opção</option>
                <option value="Nome">Nome</option>
                <option value="CPF">CPF</option>
                <option value="E-mail">E-mail</option>
              </select>

              <input type="text" class="form-control input-field" placeholder="Pesquisar" />
              <button class="btn btn-primary search-btn btn-new">Ir</button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <v-breadcrumbs bg-color="primary" :items="['Foo', 'Bar', 'Fizz']"></v-breadcrumbs>
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Razão Social
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Responsável
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Telefone
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Cidade
                </th>
                <th class="text-secondary opacity-7"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="auto in autos" :key="auto.id">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">
                        {{ auto.auto_escola.nome_fantasia }}
                      </h6>
                      <p class="text-xs text-secondary mb-0">
                        {{ auto.auto_escola.razao_social }}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs text-secondary mb-0">{{ auto.nome }}</p>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="badge badge-sm bg-gradient-success">
                    {{ auto.telefone }}
                  </span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{
                                    auto.endereco.cidade
                                  }}</span>
                </td>
                <td class="align-middle">

                  <a href="javascript:;" class="text-primary font-weight-bold text-xs" @click="editarAuto(auto.id)"
                    data-toggle="tooltip" data-original-title="Abrir cargo">Editar</a> <i
                    class="fas fa-file-import text-primary cursor-pointer ml-3"
                    @click="envioDocumento(auto.auto_escola.tipo_empresa, auto.auto_escola.galaxId, auto.auto_escola.galaxHash)"></i>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ListaAutoComponent",
  mounted() {
    this.fetchAuto();
  },
  data() {
    return {
      breadcrumbs: [{ label: "Home", to: "/dashboard-admin" }],
      autos: [],
    };
  },
  methods: {
    editarAuto(id) {
      this.$router.push({ path: `/edita-auto/${id}` });
    },
    envioDocumento(tipoEmpresa, galaxId, galaxHash) {
      console.log('------TIPO EMPRESA', tipoEmpresa);
      console.log('------GALAX ID', galaxId);
      console.log('------GALAX HASH', galaxHash);
      this.$router.push(`/cad-documento/${tipoEmpresa}/${galaxId}/${galaxHash}`);
    },

    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    async fetchAuto() {
      const token = this.$store.state.token;
      console.log(token);
      try {
        const response = await axios.get(
          this.$baseURL + `/admin/buscaAutoEscola/`,
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("---RESPONSE--", response);
        this.autos = response.data.data;
      } catch (error) {
        console.error("Erro ao buscar Auto Escolas:", error);
      }
    },

    cadAuto() {
      this.$router.push("/cad-auto");
    },
  },
};
</script>

<style scoped>
.input-field,
.search-btn,
.btn-new {
  height: 38px;
  border-radius: 0;
}

.select-field {
  width: 40px;
}

.search-input {
  flex-grow: 1;
}
</style>
