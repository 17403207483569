import ColaboradorDashboard from "../../views/DashboardColaborador.vue";


const autoRoutes = [
  {
    name: "DashboadColaborador",
    path: "/dashboard-funcionario",
    component: ColaboradorDashboard,
    meta: { requiresAuth: true, roles: ["ROLE_FUNCIONARIO"] },
  },
];

export default autoRoutes;
