<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain">
                <img
                  src="../assets/img/logos/logo-paycfc.png"
                  alt="Logo PayCFC"
                  style="width: 300px"
                />
                <div class="pb-0 card-header text-start">
                  <p class="font-weight-light mb-0">
                    Entre com seu e-mail e senha
                  </p>
                </div>
                <div class="card-body">
                  <form @submit.prevent="authenticate">
                    <div class="mb-2">
                      <input
                        v-model="email"
                        class="form-control"
                        placeholder="E-mail"
                        type="email"
                        id="email"
                        required
                      />
                    </div>
                    <div class="mb-1">
                      <input
                        v-model="password"
                        class="form-control"
                        placeholder="Senha"
                        type="password"
                        id="password"
                        required
                      />
                    </div>
                    <div class="form">
                      <label
                        ><router-link to="/reset-senha"
                          >Esqueci a senha</router-link
                        >
                      </label>
                    </div>
                    <div class="text-center">
                      <argon-button
                        class="mt-4"
                        variant="gradient"
                        color="warning"
                        fullWidth
                        size="lg"
                        type="submit"
                      >
                        Entrar
                      </argon-button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="
                  background-image: url('https://southeastit.com.au/wp-content/uploads/2022/07/business-technology.jpg');
                  background-size: cover;
                "
              >
                <span class="mask bg-gradient-warning opacity-9"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >
                  payCFC
                </h4>
                <p class="text-white position-relative">
                  Sistema de Emissão de Cobranças para Auto Escolas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
import router from "../router";
import { useToast } from "vue-toastification";
import ArgonButton from "@/components/ArgonButton.vue";

const body = document.getElementsByTagName("body")[0];

export default {
  name: "signin_comp",
  components: {
    ArgonButton,
  },
  data() {
    return {
      email: "",
      password: "",
      rememberMe: false,
    };
  },
  methods: {
    async authenticate() {
      try {
        if (this.email === "" || this.password === "") {
          console.log("Email and password are required.");
          return;
        }
        const response = await axios.post(this.$baseURL + "/auth/login", {
          email: this.email,
          password: this.password,
        });
        console.log(response);

        if (response.status === 200) {
          const token = response.data.accessToken;
          const id_auto = response.data.id_auto_escola;
          const cod_efi = response.data.cod_efi;
          const regra = response.data.roles[0];
          const id_user = response.data.id;

          console.log(token);
          console.log(regra);

          this.$store.commit("setToken", token);
          this.$store.commit("setIsAuthenticated", true);
          this.$store.commit("setUserRole", regra);
          this.$store.commit("setIdAuto", id_auto);
          this.$store.commit("setIdUsuario", id_user);
          this.$store.commit("setCodEfi", cod_efi);

          if (regra == "ROLE_AUTO_ESCOLA") {
            this.$store.commit("setIdAuto", id_auto);
            router.push("/dashboard-auto");
          } else if (regra == "ROLE_FUNCIONARIO") {
            this.$store.commit("setIdAuto", id_auto);
            router.push("/dashboard-funcionario");
          } else {
            router.push("/dashboard-admin");
          }
        } else {
          console.log("Erro");
        }
      } catch (error) {
        const toast = useToast();

        toast.error("Usuário e/ou senha inválida", {
          timeout: 4000,
        });
      }
    },
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
