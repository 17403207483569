<template>
  <BreadcrumbsListaCobrancaBreadCrumbs :breadcrumbs="breadcrumbs" :router="$router" />

  <div class="py-4 container-fluid">
    <div class="card">
      <div class="container mt-2">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12 d-flex align-items-center">
            <button class="btn btn-primary btn-new" @click="novaCobranca">
              Nova Cobrança
            </button>
          </div>
          <div class="col-lg-8 col-md-6 col-sm-12 d-flex align-items-center">
            <div class="d-flex w-90">
              <select v-model="selectedOption" class="form-control input-field">
                <option disabled selected hidden>Selecione uma opção</option>
                <option value="nome">Nome</option>
                <option value="cpf">CPF</option>
                <option value="status">Status</option>
                <option value="data">Data de Criação</option>
              </select>

              <input v-if="selectedOption === 'nome' || selectedOption === 'cpf'" v-model="searchText" type="text"
                class="form-control input-field" placeholder="Pesquisar" />
              <div v-else-if="selectedOption === 'status'">
                <select v-model="selectedStatus" class="form-control input-field">
                  <option value="">Selecione o status</option>
                  <option value="active">Ativa</option>
                  <option value="canceled">Cancelada</option>
                  <option value="closed">Encerrada</option>
                  <option value="waitingPayment">Aguardando pagamento</option>
                  <option value="inactive">Inativa</option>
                </select>
              </div>
              <div v-else-if="selectedOption === 'data'" class="d-flex">
                <input v-model="startDate" type="date" class="form-control input-field" />
                <span style="margin: 0 5px;">à</span>
                <input v-model="endDate" type="date" class="form-control input-field" />
              </div>

              <button @click="fetchCobranca" class="btn btn-primary search-btn btn-new">Ir</button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-header pb-0">
        <h6>Lista de Cobranças</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Nome
                </th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                  CPF
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Total
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Status
                </th>
                <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                  Dt. Criação
                </th>
                <th class="text-secondary opacity-7"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="cobranca in cobrancas" :key="cobranca.id">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-0 text-sm">{{ cobranca.user.nome }}</h6>
                      <p class="text-xs text-secondary mb-0">
                        {{ cobranca.user.email }}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-xs text-secondary mb-0">
                    {{ formatarCPF(cobranca.user.aluno.cpf) }}
                  </p>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-secondary text-xs font-weight-bold">
                    {{ formatarValor(cobranca.total) }}
                  </span>
                </td>
                <td class="align-middle text-center">
                  <span class="badge badge-sm" v-bind:class="getBadgeClasses(cobranca.status)">
                    {{ formatarStatus(cobranca.status) }}</span>
                </td>
                <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{
    formatarData(cobranca.createdAt)
  }}</span>
                </td>
                <td class="align-middle">
                  <button @click="abrirDetalhesCobranca(cobranca.id, cobranca.autoId)" class="btn btn-link mt-3">
                    Abrir
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BreadcrumbsListaCobrancaBreadCrumbs from "@/components/Breadcrumbs.vue";

export default {
  components: { BreadcrumbsListaCobrancaBreadCrumbs },
  mounted() {
    this.fetchCobranca();
  },
  data() {
    return {
      selectedOption: null,
      searchText: '',
      selectedStatus: '',
      startDate: '',
      endDate: '',
      breadcrumbs: [{ label: "Home", to: "/dashboard-auto" }],
      cobrancas: [],
    };
  },
  methods: {
    abrirDetalhesCobranca(id, autoId) {
      this.$router.push({
        name: "exibeCobranca",
        params: { id: id, autoId: autoId },
      });
    },
    formatarStatus(status) {
      switch (status) {
        case "active":
          return "Ativa";
        case "canceled":
          return "Cancelada";
        case "closed":
          return "Encerrada";
        case "waitingPayment":
          return "Aguardando Pagamento";
        case "inactive":
          return "Inativa";
        default:
          return status;
      }
    },
    getBadgeClasses(status) {
      switch (status) {
        case "active":
          return "bg-gradient-primary";
        case "canceled":
          return "bg-gradient-danger";
        case "closed":
          return "bg-gradient-info";
        case "waitingPayment":
          return "bg-gradient-purple";
        case "inactive":
          return "bg-gradient-dark";
        default:
          return "";
      }
    },
    formatarValor(valor) {
      if (!valor && valor !== 0) return "";

      // Converter o valor para número e formatá-lo como moeda brasileira
      const valorNumerico = parseFloat(valor);
      return valorNumerico.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
    ,
    formatarCPF(cpf) {
      if (!cpf) return "";
      cpf = cpf.replace(/\D/g, "");
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    },

    formatarData(dataString) {
      const data = new Date(dataString);
      const dia = data.getDate().toString().padStart(2, "0");
      const mes = (data.getMonth() + 1).toString().padStart(2, "0");
      const ano = data.getFullYear();
      return `${dia}/${mes}/${ano}`;
    },
    async fetchCobranca() {
      const token = this.$store.state.token;
      const id = this.$store.state.id_auto;

      try {
        let url = `${this.$baseURL}/cobranca/retornaPagamentosAlunosPorAuto/${id}?`;

        if (this.selectedOption === 'nome' && this.searchText) {
          url += `nome=${this.searchText}`;
        } else if (this.selectedOption === 'cpf' && this.searchText) {
          url += `cpf=${this.searchText}`;
        } else if (this.selectedOption === 'status' && this.selectedStatus) {
          url += `status=${this.selectedStatus}`;
        } else if (this.selectedOption === 'data' && this.startDate && this.endDate) {
          url += `dataInicio=${this.startDate}&dataFim=${this.endDate}`;
        }

        const response = await axios.get(url, {
          headers: {
            'x-access-token': token,
            'Content-Type': 'application/json',
          },
        });

        this.cobrancas = response.data.data;
      } catch (error) {
        console.error('Erro ao buscar cobrança:', error);
      }
    },

    novaCobranca() {
      this.$router.push("/gerar-cobranca");
    },
  },
};
</script>

<style scoped>
.input-field,
.search-btn,
.btn-new {
  height: 38px;
  border-radius: 0;
}

.select-field {
  width: 40px;
}

.search-input {
  flex-grow: 1;
}
</style>
