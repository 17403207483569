<template>
  <BreadcrumbsExibePacote :breadcrumbs="breadcrumbs" :router="$router" />

  <div class="py-4 container-fluid">
    <div class="card">
      <h1>Detalhes do Pacote</h1>
      <div v-if="pacote && pacote.data && pacote.data.length > 0">
        <p><strong>Curso:</strong> {{ pacote.data[0].curso }}</p>
        <p><strong>UPF:</strong> {{ pacote.data[0].upf }}</p>
        <p>
          <strong>Valor Unitário:</strong> R$ {{ pacote.data[0].val_unitario }}
        </p>
        <p>
          <strong>Carga Horária:</strong>
          {{ pacote.data[0].carga_horaria }} horas
        </p>
        <p><strong>Valor Total:</strong> R$ {{ pacote.data[0].val_total }}</p>
        <p><strong>Status:</strong> {{ pacote.data[0].status }}</p>
        <p><strong>Modalidade:</strong> {{ pacote.data[0].modalidade.nome }}</p>
        <p><strong>Categoria:</strong> {{ pacote.data[0].categoria.nome }}</p>
        <button @click="editarPacote" class="btn btn-primary">Editar</button>
      </div>
      <div v-else>
        <p>Não foi possível carregar os detalhes do pacote.</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BreadcrumbsExibePacote from "@/components/Breadcrumbs.vue";

export default {
  components: { BreadcrumbsExibePacote },

  data() {
    return {
      breadcrumbs: [
        { label: "Home", to: "/dashboard-admin" },
        { label: "Lista de Pacotes", to: "/lista-pacotes" },
      ],
      pacote: null,
    };
  },
  async created() {
    await this.buscarPacote();
  },
  methods: {
    async buscarPacote() {
      const token = this.$store.state.token;
      const id = this.$route.params.id;

      try {
        console.log("ID do pacote:", id);
        const response = await axios.get(
          this.$baseURL + `/produto/buscaProdutoPorID/${id}`,
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("---RESPONSE--", response);
        this.pacote = response.data;
      } catch (error) {
        console.error("Erro ao buscar produtos:", error);
      }
    },
    editarPacote() {
      const id = this.$route.params.id;
      this.$router.push(`/edita-pacote/${id}`);
    },
  },
};
</script>
