<template>
  <BreadcrumbsCadProduto :breadcrumbs="breadcrumbs" :router="$router" />

  <main>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="cadPacote">
                <div class="row">
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Curso</label>
                    <input v-model="curso" class="form-control mb-1" type="text" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">UPF</label>
                    <input v-model="upf" type="number" class="form-control mb-1" />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Valor Unitário</label>
                    <money3 v-model="val_unitario" class="form-control mb-1" type="text" v-bind="config" required />
                  </div>

                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Carga Horária</label>
                    <input v-model="carga_horaria" class="form-control mb-1" type="number" id="carga_horaria"
                      required />
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">Valor Total</label>
                    <money3 v-model="val_total" class="form-control mb-1" v-bind="config"></money3>
                  </div>
                  <div class="col-md-6">
                    <label for="example-text-input" class="form-control-label">É possível alterar a quantidade?</label>
                    <span v-if="!selectedOptionRadio" class="error">
                      *preencha essa informação.</span>
                    <div class="combos-container">
                      <div class="combo">
                        <label>
                          <input type="radio" v-model="selectedOptionRadio" value="S" required />
                          Sim
                        </label>
                      </div>
                      <div class="combo">
                        <label>
                          <input type="radio" v-model="selectedOptionRadio" value="N" />
                          Não
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row"></div>
                  <div class="col-md-6">
                    <label for="modalidade" class="form-control-label">Modalidade</label>
                    <select v-model="selectedModalidade" class="form-control" required>
                      <option disabled value="">
                        Selecione uma modalidade
                      </option>
                      <option v-for="modalidade in modalidades" :key="modalidade.id" :value="modalidade.id">
                        {{ modalidade.nome }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="modalidade" class="form-control-label">Categoria</label>
                    <select v-model="selectedCategoria" class="form-control" required>
                      <option disabled value="">Selecione uma categoria</option>
                      <option v-for="categoria in categorias" :key="categoria.id" :value="categoria.id">
                        {{ categoria.nome }}
                      </option>
                    </select>
                  </div>
                </div>

                <argon-button color="success" size="sm" class="ms-auto mt-2" @click="cadPacote"
                  :disabled="submitting">Salvar</argon-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import ArgonButton from "@/components/ArgonButton.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
const body = document.getElementsByTagName("body")[0];
import { Money3Component } from "v-money3";
import BreadcrumbsCadProduto from "@/components/Breadcrumbs.vue";

export default {
  name: "CadPacoteComponent",
  components: { ArgonButton, money3: Money3Component, BreadcrumbsCadProduto },

  data() {
    return {
      breadcrumbs: [
        { label: "Home", to: "/dashboard-admin" },
        { label: "Lista Pacotes", to: "/lista-pacotes" },
      ],
      config: {
        masked: false,
        prefix: "R$ ",
        suffix: "",
        thousands: ".",
        decimal: ",",
        precision: 2,
        disableNegative: false,
        disabled: false,
        min: null,
        max: null,
        allowBlank: false,
        minimumNumberOfCharacters: 0,
        shouldRound: true,
        focusOnRight: false,
      },
      selectedCategoria: null,
      selectedModalidade: "",
      showMenu: false,
      curso: "",
      upf: "",
      val_unitario: "",
      carga_horaria: "",
      val_total: "",
      selectedOptionRadio: null,
    };
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    redirecionarParaListaPacotes() {
      this.$router.push("/clientes");
    },

    async fetchCategorias() {
      const token = this.$store.state.token;
      try {
        const response = await axios.get(
          this.$baseURL + `/categoria/buscaCategorias`,
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        this.categorias = response.data.data;
      } catch (error) {
        console.error("Erro ao buscar categorias:", error);
      }
    },

    async fetchModalidades() {
      const token = this.$store.state.token;

      try {
        const response = await axios.get(
          this.$baseURL + `/modalidade/buscaModalidades/`,
          {
            headers: {
              "x-access-token": `${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        this.modalidades = response.data.data;
      } catch (error) {
        console.error("Erro ao buscar modalidades:", error);
      }
    },

    async cadPacote() {
      if (this.submitting) {
        return;
      }
      this.submitting = true;

      try {
        const toaster = createToaster({});
        const token = this.$store.state.token;
        console.log('----QTD---', this.selectedOptionRadio)

        const dados = {
          curso: this.curso,
          upf: this.upf,
          val_unitario: this.val_unitario,
          carga_horaria: this.carga_horaria,
          val_total: this.val_total,
          qtd_editavel: this.selectedOptionRadio,
          modalidadeId: this.selectedModalidade,
          categoriaId: this.selectedCategoria,
          status: "ATIVO",
        };

        try {
          const response = await axios.post(
            this.$baseURL + "/produto/novoProduto",
            dados,
            {
              headers: {
                "x-access-token": `${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (response.status === 200) {
            toaster.success("Produto cadastrado com sucesso!", {
              timeout: 2000,
            });

            this.$router.push("/lista-pacotes");
          } else {
            console.log(response.data.message);
            toaster.success(response.data.message, {
              timeout: 4000,
            });
          }
        } catch (error) {
          console.log(error);
          console.error("Error:", error);
        }
      } finally {
        this.submitting = false;
      }
    },
  },

  created() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip();
    this.fetchModalidades();
    this.fetchCategorias();
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  },
};
</script>
<style>
.error {
  color: red;
  font-size: 10px;
}
</style>
